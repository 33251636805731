export interface DataState {
  lat: number,
  lng: number,
  smells: Array<Smell>,
  strength: string,
  isOffensive: string,
  duration: string,
  name: string,
  phone: string,
  email: string,
  comments: string,
  timestamp: string
}

export const initialDataState: DataState = {
  lat: -35.400662,
  lng: 149.166073,
  smells: [
    {
      name: 'Fragrant',
      isSelected: false
    }, {
      name: 'Sour\nBody-odour',
      isSelected: false
    }, {
      name: 'Burnt\nSmoky',
      isSelected: false
    }, {
      name: 'Rotten eggs\nSulfide',
      isSelected: false
    }, {
      name: 'Meaty',
      isSelected: false
    }, {
      name: 'Fishy',
      isSelected: false
    }, {
      name: 'Oily\nFatty',
      isSelected: false
    }, {
      name: 'Faecal\nManure\nSewer',
      isSelected: false
    }, {
      name: 'Herbal\nGreen\nCut grass',
      isSelected: false
    }, {
      name: 'Compost',
      isSelected: false
    }, {
      name: 'Seaweed\nMangroves',
      isSelected: false
    }, {
      name: 'Musty\nEarthy\nMouldy',
      isSelected: false
    }, {
      name: 'Household gas',
      isSelected: false
    }, {
      name: 'Diesel\nCar fumes',
      isSelected: false
    }, {
      name: 'Asphalt\nBitumen',
      isSelected: false
    }, {
      name: 'Others',
      isSelected: false
    }
  ],
  strength: '',
  isOffensive: '',
  duration: '',
  name: '',
  phone: '',
  email: '',
  comments: '',
  timestamp: ''
}

export interface Smell {
  name: string,
  isSelected: boolean
}

