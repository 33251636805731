import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

// Definitions can be combined in createMuiTheme, so it is better to group similar properties together.
// Refer to https://material-ui.com/customization/palette/ and https://material-ui.com/customization/default-theme/.
const palette = {
  palette: {
    text: {
      primary: '#000000'
    },
    background: {
      default: '#f5f5f5'
    },
    primary: {
      light: '#9d46ff',
      main: '#6200ee',
      dark: '#5600e8',
      contrastText: '#fff'
    },
    secondary: {
      light: '#9d46ff',
      main: '#6200ee',
      dark: '#5600e8',
      contrastText: '#fff'
    }
  }
}

// responsiveFontSizes() will autosize the font according to device display density
// See https://material-ui.com/customization/typography/#responsive-font-sizes
const theme = responsiveFontSizes(createMuiTheme(palette))

export default theme