import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mainSetShowAboutDialog } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function AboutDialog () {

  const dispatch = useDispatch()
  const showAboutDialog = useSelector((state: AppState) => state.main.showAboutDialog)

  return (
    <Dialog
      open={showAboutDialog}
      onClose={onAboutDialogClose}>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <a href="https://www.erm.com/" target="_blank" rel="noreferrer">
            Environmental Resources Management (ERM)
          </a>
          {
            ' is a leading global provider of environmental, health, safety, risk, social consulting services ' +
            'and sustainability-related services. The current application has been designed to enable our ' +
            'clients to log odour events in real-time and in a manner consistent with international ' +
            'standards for odour observation.'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAboutDialogClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )

  function onAboutDialogClose () {
    dispatch(mainSetShowAboutDialog(false))
  }

}

export default AboutDialog