import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dataReset } from '../../redux/slice/DataSlice'
import { mainReset, mainSetShowCancelDialog } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function CancelDialog () {

  const history = useHistory()
  const dispatch = useDispatch()
  const showCancelDialog = useSelector((state: AppState) => state.main.showCancelDialog)

  return (
    <Dialog
      open={showCancelDialog}
      onClose={onCancelDialogClose}>
      <DialogTitle>Cancel report?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel report?
          All entered information will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelDialogYes} color="primary">
          Yes
        </Button>
        <Button onClick={onCancelDialogClose} color="primary" variant="contained">
          No
        </Button>
      </DialogActions>
    </Dialog>
  )

  function onCancelDialogYes () {
    dispatch(mainReset())
    dispatch(dataReset())
    history.push('/')
  }

  function onCancelDialogClose () {
    dispatch(mainSetShowCancelDialog(false))
  }

}

export default CancelDialog