import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import { auth } from '../../firebase/Firebase'

function SignInPage (props: { isSignIn: boolean | null }) {

  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(' ')
  const [passwordError, setPasswordError] = useState(' ')

  const [allInputValid, setAllInputValid] = useState(false)
  useEffect(() => {
    setAllInputValid(
      email.trim().length !== 0 &&
      password.length !== 0 &&
      emailError === ' ' &&
      passwordError === ' '
    )
  }, [email, password, emailError, passwordError])

  const [showPassword, setShowPassword] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [showSignInOverlay, setShowSignInOverlay] = useState(false)

  return (
    <Container maxWidth='xs' className={classes.root}>

      {(props.isSignIn !== null && !props.isSignIn && !showSignInOverlay) &&
      <React.Fragment>
        <Typography className={classes.title} variant='h5' align='center'>
          Sign In
        </Typography>

        <TextField
          className={classes.textField}
          fullWidth
          name='email'
          value={email}
          label='Email'
          inputProps={{ inputMode: 'email' }}
          variant='outlined'
          error={emailError !== ' '}
          helperText={emailError}
          onChange={onTextFieldChange}/>
        <FormControl
          fullWidth
          error={passwordError !== ' '}
          variant="outlined">
          <InputLabel>Password</InputLabel>
          <OutlinedInput
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={onTextFieldChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={onVisibilityClick}
                  edge="end">
                  {showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }/>
          <FormHelperText>{passwordError}</FormHelperText>
        </FormControl>

        <Button
          className={classes.signInButton}
          variant='contained'
          color='primary'
          disabled={!allInputValid}
          onClick={onSignInClick}>
          Sign In
        </Button>
      </React.Fragment>}

      {(props.isSignIn === null || showSignInOverlay) &&
      <React.Fragment>
        <CircularProgress/>
        <Typography className={classes.title} variant='h5' align='center'>
          Signing in…
        </Typography>
      </React.Fragment>}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackBarMessage.length !== 0}
        autoHideDuration={4000}
        onClose={onSnackBarClose}
        message={snackBarMessage}
      />

    </Container>
  )

  function onTextFieldChange (event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    switch (event.target.name) {
      case 'email':
        const email = value.trim()
        setEmail(email)
        if (email.length === 0) {
          setEmailError('Required')
        } else {
          if (isEmail(email)) {
            setEmailError(' ')
          } else {
            setEmailError('Invalid email')
          }
        }
        break
      case 'password':
        const password = value
        setPassword(password)
        if (password.length === 0) {
          setPasswordError('Required')
        } else if (password.length < 6) {
          setPasswordError('Password must be at least 6 characters')
        } else {
          setPasswordError(' ')
        }
        break
    }
  }

  function onVisibilityClick () {
    setShowPassword(!showPassword)
  }

  function onSignInClick () {
    setShowSignInOverlay(true)
    auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        // Do nothing, authState is monitored by reportPage
      })
      .catch((error) => {
        console.log(error)
        if (error.code === 'auth/wrong-password') {
          setSnackBarMessage('Wrong password. Please try again.')
          setShowSignInOverlay(false)
        } else {
          setSnackBarMessage('Invalid email. Please try again.')
          setShowSignInOverlay(false)
        }
      })
  }

  function onSnackBarClose () {
    setSnackBarMessage('')
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  title: {
    margin: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(1)
  },
  signInButton: {
    margin: theme.spacing(2),
    height: '44px'
  }
}))

export default SignInPage