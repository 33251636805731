import { Box, Button, Card, CardActionArea, makeStyles, Theme, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { dataSetSmells } from '../../redux/slice/DataSlice'
import { mainSetShowCancelDialog, mainSetSmellPageDone } from '../../redux/slice/MainSlice'
import { Smell } from '../../redux/state/DataState'
import { AppState } from '../../redux/Stores'
import { WhiteTextTypography } from '../../utils/WhiteTextTypography'

const colors = [
  '#00A2F9',
  '#006AC7',
  '#807F80',
  '#FFBB00',
  '#C26000',
  '#FF614F',
  '#6C3500',
  '#815E00',
  '#90C34D',
  '#90C34B',
  '#00994E',
  '#009950',
  '#FFA9FF',
  '#A4179D',
  '#A4179D',
  '#000000'
]

function SmellPage () {

  const classes = useStyles()
  const history = useHistory()

  const [gridSize, setGridSize] = useState('')

  const dispatch = useDispatch()
  const mapPageDone = useSelector((state: AppState) => state.main.mapPageDone)
  const smellPageDone = useSelector((state: AppState) => state.main.smellPageDone)
  const smells = useSelector((state: AppState) => state.data.smells)

  useEffect(() => {
    calculateGridSize()
    // scroll to top in case it overflows the viewport
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  // Calculate the amount of columns required to fit the grids into viewport
  function calculateGridSize () {
    const tilesCount = 16
    // screen width - margins
    const gridContainerWidth = window.innerWidth - 16
    // continueButton(52) + cancelButton(52)
    const bottomContainerHeight = 104
    // screen height - appBar(56) - title1(~44) - title2(~40) - bottomContainer - margins(top+bottom)
    const gridContainerHeight = window.innerHeight - 56 - 40 - bottomContainerHeight - 16
    let colCount = 2
    let gridSize = 100
    for (colCount; colCount < 17; colCount++) {
      // The tiles are square, so the new height can be calculated knowing how many rows are generated
      const newHeight = gridContainerWidth / colCount * Math.ceil(tilesCount / colCount)
      const newGridSize = gridContainerWidth / colCount - 8       // margin is 4px per side
      // Do not let gridSize fall below a minimum size
      if (newGridSize < 80) {
        break
      } else {
        // Use the new grid size
        gridSize = newGridSize
        // Continue to increase column count until we are just within bound
        if (newHeight < gridContainerHeight) {
          break
        }
      }
    }
    setGridSize(`${gridSize}px`)
  }

  // If previous page was not completed, go back
  if (!mapPageDone) {
    return (<Redirect to='/'/>)
  }
  return (
    <Box className={classes.root}>

      <Typography className={classes.title} variant='h5' align='center'>
        What does it smell like?
      </Typography>
      <Typography className={classes.title2} variant='body2' align='center'>
        You may select multiple answers.
      </Typography>

      <Box className={classes.gridContainer}>
        {smells.map((value, index) => (
          getSmellGrid(value, index.toString())
        ))}
      </Box>

      <Box className={classes.fillerContainer}/>

      <Box className={classes.bottomContainer}>
        <Button
          className={classes.continueButton}
          variant='contained'
          color='primary'
          disabled={!smellPageDone}
          onClick={onContinueClick}>
          Continue
        </Button>
        <Button
          className={classes.cancelButton}
          color='primary'
          onClick={onCancelClick}>
          Cancel
        </Button>
      </Box>

    </Box>
  )

  function getSmellGrid (smell: Smell, index: string) {
    return (
      <Card
        style={{
          backgroundColor: colors[parseInt(index)],
          margin: '4px',
          width: gridSize,
          height: gridSize
        }}
        key={`a${index}`}>
        <CardActionArea
          className={classes.cardActionArea}
          key={`b${index}`}
          id={index}
          onClick={onSmellClick}>
          <WhiteTextTypography className={classes.smellName} key={`c${index}`} variant='body2'>
            {smell.name}
          </WhiteTextTypography>
          {smell.isSelected && <Card className={classes.cardMask} key={`d${index}`}/>}
          {smell.isSelected && <CheckCircleIcon className={classes.tickIcon} key={`e${index}`}/>}
        </CardActionArea>
      </Card>
    )
  }

  function onSmellClick (event: React.MouseEvent<HTMLElement>) {
    let atLeastOneSmellSelected = false
    const newSmells = smells.map((smell, i) => {
      if (i !== parseInt(event.currentTarget.id)) {
        if (smell.isSelected) atLeastOneSmellSelected = true
        return smell
      } else {
        if (!smell.isSelected) atLeastOneSmellSelected = true
        return {
          ...smell,
          isSelected: !smell.isSelected
        }
      }
    })
    dispatch(dataSetSmells(newSmells))
    dispatch(mainSetSmellPageDone(atLeastOneSmellSelected))
  }

  function onContinueClick () {
    if (smellPageDone) {
      history.push('/strength')
    }
  }

  function onCancelClick () {
    dispatch(mainSetShowCancelDialog(true))
  }

}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    marginTop: theme.spacing(2)
  },
  title2: {
    margin: theme.spacing(1)
  },
  gridContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'flex-start'
  },
  cardActionArea: {
    width: '100%',
    height: '100%'
  },
  smellName: {
    verticalAlign: 'center',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'    // Use whiteSpace: 'pre-wrap' to fold text at \n
  },
  cardMask: {
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: 'white',
    opacity: '0.6',
    width: '100%',
    height: '100%'
  },
  tickIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: theme.palette.primary.main,
    width: '20%',
    height: '20%'
  },
  fillerContainer: {
    flex: '1'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  continueButton: {
    margin: theme.spacing(1),
    height: '44px'
  },
  cancelButton: {
    marginBottom: theme.spacing(2)
  }
}))

export default SmellPage