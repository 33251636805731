import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { auth, db } from '../../firebase/Firebase'
import { dataReset } from '../../redux/slice/DataSlice'
import { mainReset } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function SubmitPage () {

  const classes = useStyles()
  const history = useHistory()

  const [openFailedDialog, setOpenFailedDialog] = useState(false)
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)

  const dispatch = useDispatch()
  const mapPageDone = useSelector((state: AppState) => state.main.mapPageDone)
  const smellPageDone = useSelector((state: AppState) => state.main.smellPageDone)
  const strengthPageDone = useSelector((state: AppState) => state.main.strengthPageDone)
  const offensivePageDone = useSelector((state: AppState) => state.main.offensivePageDone)
  const contactPageDone = useSelector((state: AppState) => state.main.contactPageDone)
  const supportPageDone = useSelector((state: AppState) => state.main.supportPageDone)
  const data = useSelector((state: AppState) => state.data)

  useEffect(() => {
    // This page is shared by supportPage and other pages
    if (supportPageDone) {
      submitFirestore('supports')
    } else if (
      mapPageDone &&
      smellPageDone &&
      strengthPageDone &&
      offensivePageDone &&
      contactPageDone
    ) {
      submitFirestore('reports')
    } else {
      // This is an illegal direct access to this page
      resetApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function submitFirestore (type: string) {
    auth.signInAnonymously().then(() => {
      let newData
      if (type === 'supports') {
        newData = {
          name: data.name.trim(),
          phone: data.phone,
          email: data.email,
          comments: data.comments.trim(),
          timestamp: new Date().toISOString()
        }
      } else {
        newData = {
          ...data,
          name: data.name.trim(),
          comments: data.comments.trim(),
          timestamp: new Date().toISOString()
        }
      }
      db.collection(type).add(newData).then(() => {
        setOpenSuccessDialog(true)
      }).catch((error) => {
        console.log(error)
        setOpenFailedDialog(true)
      })
    }).catch((error) => {
      console.log(error)
      setOpenFailedDialog(true)
    })
  }

  // Note that dialog will throw 'findDOMNode is deprecated in StrictMode' warning.
  // This is due to the Material UI library itself, not an issue.
  return (
    <Box className={classes.root}>
      <CircularProgress/>
      <Typography className={classes.title} variant='h5' align='center'>
        Submitting the report…
      </Typography>
      {getFailedDialog()}
      {getSuccessDialog()}
    </Box>
  )

  function getFailedDialog () {
    return (
      <Dialog
        open={openFailedDialog}
        onClose={onFailedDialogClose}>
        <DialogTitle>Submission failed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'We\'re sorry. Something went wrong and the report was not submitted. Please contact us at '}
            <a href='mailto: odourapp.support@erm.com'>
              odourapp.support@erm.com
            </a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onFailedDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function onFailedDialogClose () {
    resetApp()
  }

  function getSuccessDialog () {
    return (
      <Dialog
        open={openSuccessDialog}
        onClose={onSuccessDialogClose}>
        <DialogTitle>Report submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for the report. Stay safe!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSuccessDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function onSuccessDialogClose () {
    resetApp()
  }

  function resetApp () {
    dispatch(mainReset())
    dispatch(dataReset())
    history.push('/')
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  title: {
    margin: theme.spacing(2)
  }
}))

export default SubmitPage