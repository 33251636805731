import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

function NotFoundPage () {

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant='h5' align='center'>
        Page not found
      </Typography>
      <Button
        className={classes.backButton}
        variant='contained'
        onClick={backButtonClick}>
        Go Back
      </Button>
    </Box>
  )

  function backButtonClick () {
    window.location.replace('/')
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  title: {
    margin: theme.spacing(2)
  },
  backButton: {
    margin: theme.spacing(1),
    height: '44px'
  }
}))

export default NotFoundPage