import { AppBar, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { dataReset } from '../../redux/slice/DataSlice'
import {
  mainReset,
  mainSetAppBarHeight,
  mainSetShowAboutDialog,
  mainSetShowSupportDialog
} from '../../redux/slice/MainSlice'

function TopAppBar () {

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const appBarRef = useRef<any>()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const appBar = appBarRef.current
    if (appBar != null) {
      dispatch(mainSetAppBarHeight(appBar.clientHeight))
    }
  }, [dispatch])

  return (
    <AppBar className={classes.root} ref={appBarRef}>
      <Toolbar>

        {showBackButton() &&
        <IconButton
          className={classes.backButton}
          edge='start'
          color='inherit'
          onClick={onBackClick}>
          <ArrowBackIcon/>
        </IconButton>}

        <Typography className={classes.title} variant='h6'>
          Report Odour
        </Typography>

        {showMenuButton() &&
        <div>
          <IconButton
            color='inherit'
            edge='end'
            onClick={onMenuOpen}>
            <MoreVertIcon/>
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={menuOpen}
            onClose={onMenuClose}>
            <MenuItem onClick={onAboutClick}>About</MenuItem>
            {
              showContactSupport() &&
              <MenuItem onClick={onSupportClick}>Contact support</MenuItem>
            }
          </Menu>
        </div>}

      </Toolbar>
    </AppBar>
  )

  function showBackButton () {
    // Show for these paths
    return (
      location.pathname === '/smell' ||
      location.pathname === '/strength' ||
      location.pathname === '/off' ||
      location.pathname === '/contact' ||
      location.pathname === '/support' ||
      location.pathname === '/report'
    )
  }

  function showMenuButton () {
    // Show for this path
    return (
      location.pathname === '/' ||
      location.pathname === '/smell' ||
      location.pathname === '/strength' ||
      location.pathname === '/off' ||
      location.pathname === '/contact' ||
      location.pathname === '/support'
    )
  }

  function showContactSupport () {
    // Don't show for this path
    return location.pathname !== '/support'
  }

  function onBackClick () {
    // For supportPage and reportPage, we restart the app
    if (location.pathname === '/support' ||
      location.pathname === '/report'
    ) {
      dispatch(mainReset())
      dispatch(dataReset())
      history.push('/')
    } else {
      history.goBack()
    }
  }

  function onMenuOpen (event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.target as HTMLElement)
    setMenuOpen(true)
  }

  function onAboutClick () {
    onMenuClose()
    dispatch(mainSetShowAboutDialog(true))
  }

  function onSupportClick () {
    onMenuClose()
    dispatch(mainSetShowSupportDialog(true))
  }

  function onMenuClose () {
    setAnchorEl(null)
    setMenuOpen(false)
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0'
  },
  backButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flex: '1'         // Make the title fill remaining width
  }
}))

export default TopAppBar