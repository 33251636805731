import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialMainState } from '../state/MainState'

const mainSlice = createSlice({
  name: 'main',
  initialState: initialMainState,
  reducers: {
    mainReset (state) {
      return {
        ...initialMainState,
        appBarHeight: state.appBarHeight
      }
    },
    mainSetAppBarHeight (state, action: PayloadAction<number>) {
      state.appBarHeight = action.payload
    },
    mainSetMapPageDone (state, action: PayloadAction<boolean>) {
      state.mapPageDone = action.payload
    },
    mainSetSmellPageDone (state, action: PayloadAction<boolean>) {
      state.smellPageDone = action.payload
    },
    mainSetStrengthPageDone (state, action: PayloadAction<boolean>) {
      state.strengthPageDone = action.payload
    },
    mainSetOffensivePageDone (state, action: PayloadAction<boolean>) {
      state.offensivePageDone = action.payload
    },
    mainSetContactPageDone (state, action: PayloadAction<boolean>) {
      state.contactPageDone = action.payload
    },
    mainSetSupportPageDone (state, action: PayloadAction<boolean>) {
      state.supportPageDone = action.payload
    },
    mainSetShowCancelDialog (state, action: PayloadAction<boolean>) {
      state.showCancelDialog = action.payload
    },
    mainSetShowAboutDialog (state, action: PayloadAction<boolean>) {
      state.showAboutDialog = action.payload
    },
    mainSetShowSupportDialog (state, action: PayloadAction<boolean>) {
      state.showSupportDialog = action.payload
    },
    mainSetShowPrivacyPolicyDialog (state, action: PayloadAction<boolean>) {
      state.showPrivacyPolicyDialog = action.payload
    }
  }
})

export const {
  mainReset,
  mainSetAppBarHeight,
  mainSetContactPageDone,
  mainSetMapPageDone,
  mainSetOffensivePageDone,
  mainSetShowAboutDialog,
  mainSetShowCancelDialog,
  mainSetShowSupportDialog,
  mainSetSmellPageDone,
  mainSetStrengthPageDone,
  mainSetSupportPageDone,
  mainSetShowPrivacyPolicyDialog
} = mainSlice.actions

export default mainSlice.reducer