import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Typography
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { auth, db } from '../../firebase/Firebase'
import { Smell } from '../../redux/state/DataState'
import SignInPage from './SignInPage'

const reportsCsvHeaders = [
  { label: 'Time Submitted', key: 'timestamp' },
  { label: 'Name', key: 'name' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Latitude', key: 'lat' },
  { label: 'Longitude', key: 'lng' },
  { label: 'Strength', key: 'strength' },
  { label: 'Offensive', key: 'isOffensive' },
  { label: 'Duration', key: 'duration' },
  { label: 'Fragrant', key: 'Fragrant' },
  { label: 'Sour\nBody-odour', key: 'Sour\nBody-odour' },
  { label: 'Burnt\nSmoky', key: 'Burnt\nSmoky' },
  { label: 'Rotten eggs\nSulfide', key: 'Rotten eggs\nSulfide' },
  { label: 'Meaty', key: 'Meaty' },
  { label: 'Fishy', key: 'Fishy' },
  { label: 'Oily\nFatty', key: 'Oily\nFatty' },
  { label: 'Faecal\nManure\nSewer', key: 'Faecal\nManure\nSewer' },
  { label: 'Herbal\nGreen\nCut grass', key: 'Herbal\nGreen\nCut grass' },
  { label: 'Compost', key: 'Compost' },
  { label: 'Seaweed\nMangroves', key: 'Seaweed\nMangroves' },
  { label: 'Musty\nEarthy\nMouldy', key: 'Musty\nEarthy\nMouldy' },
  { label: 'Household gas', key: 'Household gas' },
  { label: 'Diesel\nCar fumes', key: 'Diesel\nCar fumes' },
  { label: 'Asphalt\nBitumen', key: 'Asphalt\nBitumen' },
  { label: 'Others', key: 'Others' },
  { label: 'Comments', key: 'comments' }
]

const supportsCsvHeaders = [
  { label: 'Time Submitted', key: 'timestamp' },
  { label: 'Name', key: 'name' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Comments', key: 'comments' }
]

function ReportPage () {

  const classes = useStyles()
  const csvRef = useRef<any>()

  useEffect(() => {
    // scroll to top in case it overflows the viewport
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
    auth.onAuthStateChanged((user) => {
      const isSignIn = !!user?.email
      setIsSignIn(isSignIn)
    })
  }, [])

  const [downloadType, setDownloadType] = useState('Odour Reports')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState(new Date())
  const [dateHelperText, setDateHelperText] = useState(' ')
  const [showDownloadingOverlay, setShowDownloadingOverlay] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileHeaders, setFileHeaders] = useState<Array<{ label: string, key: string }>>([])
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSignIn, setIsSignIn] = useState<boolean | null>(null)        // Use null before auth returns any value

  const [downloadData, setDownloadData] = useState<Array<any>>([])
  useEffect(() => {
    // Anytime the download data is changed, we download it if there is data
    if (downloadData.length > 0) {
      setTimeout(
        () => {
          setShowDownloadingOverlay(false)
          // Simulating a click on the link to start download
          csvRef.current?.link?.click()
        })
    }
  }, [downloadData])

  return (
    <React.Fragment>
      {(isSignIn === null || !isSignIn) && <SignInPage isSignIn={isSignIn}/>}

      {isSignIn &&
      <Container maxWidth='xs' className={classes.root}>

        {!showDownloadingOverlay &&
        <React.Fragment>
          <Typography className={classes.title} variant='h5' align='center'>
            Download CSV
          </Typography>

          <FormControl
            className={classes.downloadType}
            fullWidth
            variant='outlined'>
            <InputLabel>Download type</InputLabel>
            <Select
              value={downloadType}
              label='Download type'
              onChange={onDownloadTypeChange}>
              <MenuItem value={'Odour Reports'}>Odour Reports</MenuItem>
              <MenuItem value={'Customer Supports'}>Customer Supports</MenuItem>
            </Select>
          </FormControl>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              clearable
              disableFuture
              margin='normal'
              inputVariant='outlined'
              label='Start date (dd/mm/yyyy)'
              format='dd/MM/yyyy'
              error={dateHelperText !== ' '}
              value={startDate}
              onChange={onStartDateChange}
            />
            <DatePicker
              fullWidth
              disableFuture
              margin='normal'
              inputVariant='outlined'
              label='End date (dd/mm/yyyy)'
              format='dd/MM/yyyy'
              error={dateHelperText !== ' '}
              value={endDate}
              onChange={onEndDateChange}
            />
          </MuiPickersUtilsProvider>

          <Typography className={classes.title} variant='caption' align='center'>
            Leaving start date empty will include all records before end date.
          </Typography>
          <Typography className={classes.helperText} variant='caption' align='center'>
            {dateHelperText}
          </Typography>

          <Box className={classes.fillerContainer}/>

          <Button
            className={classes.downloadButton}
            variant='contained'
            color='primary'
            disabled={dateHelperText !== ' '}
            onClick={onDownloadClick}>
            Download Report
          </Button>
          <CSVLink
            filename={fileName}
            ref={csvRef}
            headers={fileHeaders}
            data={downloadData}
            target='_blank'/>

        </React.Fragment>}

        {showDownloadingOverlay &&
        <Container className={classes.downloadOverlay}>
          <CircularProgress/>
          <Typography className={classes.title} variant='h5' align='center'>
            Downloading report…
          </Typography>
        </Container>}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={snackBarMessage.length !== 0}
          autoHideDuration={4000}
          onClose={onSnackBarClose}
          message={snackBarMessage}/>

      </Container>}
    </React.Fragment>
  )

  function onStartDateChange (startDate: Date | null) {
    startDate?.setHours(0, 0, 0, 0)
    setStartDate(startDate)
    compareDates(startDate, endDate)
  }

  function onDownloadTypeChange (event: React.ChangeEvent<{ value: unknown }>) {
    setDownloadType(event.target.value as string)
  }

  function onEndDateChange (endDate: Date | null) {
    endDate!.setHours(23, 59, 59, 999)
    setEndDate(endDate!)
    compareDates(startDate, endDate!)
  }

  function compareDates (startDate: Date | null, endDate: Date) {
    // If no start date is selected, it will be the earliest possible and no collision
    if (startDate === null) {
      setDateHelperText(' ')
    } else {
      if (startDate.getTime() >= endDate.getTime()) {
        setDateHelperText('End date cannot be earlier than start date.')
      } else {
        setDateHelperText(' ')
      }
    }
  }

  function onDownloadClick () {
    setShowDownloadingOverlay(true)
    let startTime: Date
    if (startDate !== null) {
      startTime = startDate
    } else {
      startTime = new Date()
      startTime.setFullYear(2000)     // Set some arbitrary early year
    }
    const endTime = endDate
    if (downloadType === 'Odour Reports') {
      downloadReports(startTime.toISOString(), endTime.toISOString())
    } else {
      downloadSupports(startTime.toISOString(), endTime.toISOString())
    }
  }

  function downloadReports (startTime: string, endTime: string) {
    db.collection('reports')
      .where('timestamp', '>=', startTime)
      .where('timestamp', '<=', endTime)
      .orderBy('timestamp')
      .get()
      .then((docs) => {
        const downloadData: Array<any> = []
        docs.forEach((doc) => {
          const data = doc.data() as Record<string, any>
          data.smells.forEach((smell: Smell) => {
            data[smell.name] = smell.isSelected ? 'Yes' : 'No'
          })
          delete data.smells
          downloadData.push(data)
        })
        printCSV(downloadData)
      })
      .catch((error) => {
        console.log(error)
        setShowDownloadingOverlay(true)
        setSnackBarMessage('Download failed. Please try again.')
      })
  }

  function downloadSupports (startTime: string, endTime: string) {
    db.collection('supports')
      .where('timestamp', '>=', startTime)
      .where('timestamp', '<=', endTime)
      .orderBy('timestamp')
      .get()
      .then((docs) => {
        const downloadData: Array<any> = []
        docs.forEach((doc) => {
          downloadData.push(doc.data())
        })
        printCSV(downloadData)
      })
      .catch((error) => {
        console.log(error)
        setShowDownloadingOverlay(true)
        setSnackBarMessage('Download failed. Please try again.')
      })
  }

  function printCSV (downloadData: Array<any>) {
    // Show error for empty data
    if (downloadData.length === 0) {
      setShowDownloadingOverlay(false)
      setSnackBarMessage('No report was made during the selected period.')
    } else {
      let fileName
      if (startDate !== null) {
        fileName = `Report-(${formatDate(startDate)})-(${formatDate(endDate)}).csv`
      } else {
        fileName = `Report-(${formatDate(endDate)}).csv`
      }
      let fileHeaders
      if (downloadType === 'Odour Reports') {
        fileHeaders = reportsCsvHeaders
      } else {
        fileHeaders = supportsCsvHeaders
      }
      setFileHeaders(fileHeaders)
      setFileName(fileName)
      setDownloadData(downloadData)
    }
  }

  function formatDate (date: Date) {
    return format(date, 'yyyy-MM-dd')
  }

  function onSnackBarClose () {
    setSnackBarMessage('')
  }

}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  title: {
    margin: theme.spacing(2)
  },
  downloadType: {
    marginBottom: theme.spacing(1)
  },
  helperText: {
    color: 'red',
    margin: theme.spacing(1)
  },
  fillerContainer: {
    flex: '1'
  },
  downloadButton: {
    margin: theme.spacing(2),
    height: '44px'
  },
  downloadOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
}))

export default ReportPage