import { Box, Fab, makeStyles, Paper, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dataSetLatLng } from '../../redux/slice/DataSlice'
import { mainSetMapPageDone } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function MapPage () {

  const classes = useStyles()
  const history = useHistory()

  const [isMapFirstLoaded, setIsMapFirstLoaded] = useState(true)

  const dispatch = useDispatch()
  const lat = useSelector((state: AppState) => state.data.lat)
  const lng = useSelector((state: AppState) => state.data.lng)
  const mapPageDone = useSelector((state: AppState) => state.main.mapPageDone)

  return (
    <Box className={classes.root}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyD0SMsqHKNgG610mio04Gp9BEmksn8uN-U' }}
        center={{
          lat: lat,
          lng: lng
        }}
        options={{
          fullscreenControl: false,
          mapTypeId: 'hybrid'
        }}
        onTilesLoaded={onMapTilesLoaded}
        onChange={onMapChange}
        defaultZoom={17}>
      </GoogleMapReact>

      <CenterFocusStrongIcon className={classes.centerMarker}/>

      <Paper className={classes.topInstruction}>
        <Typography variant='body2'>
          Please move the map to position the centre marker at the place where you experienced the odour.
        </Typography>
      </Paper>

      <Fab
        className={classes.reportButton}
        variant='extended'
        color='primary'
        onClick={onReportButtonClick}>
        <AddIcon className={classes.reportButtonIcon}/>
        Report Odour
      </Fab>

    </Box>
  )

  // Once the tile is loaded for the first time, request user location
  function onMapTilesLoaded () {
    if (isMapFirstLoaded && !mapPageDone) {
      setIsMapFirstLoaded(false)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(dataSetLatLng(position.coords.latitude, position.coords.longitude))
        })
    }
  }

  // When the map is moved around, capture the center position of the map
  function onMapChange (change: ChangeEventValue) {
    dispatch(dataSetLatLng(change.center.lat, change.center.lng))
  }

  function onReportButtonClick () {
    dispatch(mainSetMapPageDone(true))
    history.push('/smell')
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  topInstruction: {
    position: 'absolute',
    width: '80%',
    top: '0',
    left: '50%',
    transform: 'translate(-50%,0%)',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  centerMarker: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '40px',
    height: '40px',
    transform: 'translate(-50%, -50%)',
    color: 'white'
  },
  reportButton: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%,0%)',
    marginBottom: theme.spacing(4)
  },
  reportButtonIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default MapPage