import { combineReducers, configureStore } from '@reduxjs/toolkit'
import DataSlice from './slice/DataSlice'
import MainSlice from './slice/MainSlice'

const rootReducer = combineReducers({
  main: MainSlice,
  data: DataSlice
})

export type AppState = ReturnType<typeof rootReducer>

const store = configureStore({ reducer: rootReducer })

export default store