import { Box, Button, Card, CardActionArea, Container, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { dataSetStrength } from '../../redux/slice/DataSlice'
import { mainSetShowCancelDialog, mainSetStrengthPageDone } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

interface Strength {
  level: string,
  color: string,
  textColor: string,
  isSelected: boolean,
  description: string
}

function StrengthPage () {

  const classes = useStyles()
  const history = useHistory()

  const [strengths, setStrengths] = useState([
    {
      level: 'Extremely strong',
      color: '#000000',
      textColor: 'white',
      isSelected: false,
      description: 'Occurs very rarely in a field situation. An offensive odour cannot be tolerated. ' +
        'Reaction it to immediately mitigate exposure.'
    }, {
      level: 'Very strong',
      color: '#C00000',
      textColor: 'white',
      isSelected: false,
      description: 'The odour character is clearly recognisable. ' +
        'Reaction it to an offensive odour is mitigate exposure.'
    }, {
      level: 'Strong',
      color: '#F17171',
      textColor: 'white',
      isSelected: false,
      description: 'The odour character is clearly recognisable. ' +
        'For an offensive type of odour, exposure is unpleasant / undesirable.'
    }, {
      level: 'Distinct',
      color: '#FFC000',
      textColor: 'black',
      isSelected: false,
      description: '\tThe odour character is clearly recognisable. ' +
        'The odour is tolerable – even for an offensive odour.'
    }, {
      level: 'Weak',
      color: '#FFFF00',
      textColor: 'black',
      isSelected: false,
      description: 'A detectable weak odour stronger than very weak and less strong than distinct.'
    }, {
      level: 'Very Weak',
      color: '#00B050',
      textColor: 'black',
      isSelected: false,
      description: 'Odour only just detectable.'
    }
  ])

  const dispatch = useDispatch()
  const mapPageDone = useSelector((state: AppState) => state.main.mapPageDone)
  const smellPageDone = useSelector((state: AppState) => state.main.smellPageDone)
  const strengthPageDone = useSelector((state: AppState) => state.main.strengthPageDone)

  const strength = useSelector((state: AppState) => state.data.strength)
  useEffect(() => {
    setStrengths((strengths) =>
      strengths.map(_strength => {
        return {
          ..._strength,
          isSelected: _strength.level === strength
        }
      })
    )
  }, [strength])

  useEffect(() => {
    // scroll to top in case it overflows the viewport
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  // If previous page was not completed, go back
  if (
    !mapPageDone ||
    !smellPageDone
  ) {
    return (<Redirect to='/smell'/>)
  }
  return (
    <Container maxWidth='xs' className={classes.root}>

      <Typography className={classes.title} variant='h5' align='center'>
        How strong is the odour?
      </Typography>

      <Box className={classes.centerContainer}>
        {strengths.map((strength, index) => (
          getStrengthCard(strength, index.toString())
        ))}
        {getDescription()}
      </Box>

      <Box className={classes.fillerContainer}/>

      <Box className={classes.bottomContainer}>
        <Button
          className={classes.continueButton}
          variant='contained'
          color='primary'
          disabled={!strengthPageDone}
          onClick={onContinueClick}>
          Continue
        </Button>
        <Button
          className={classes.cancelButton}
          color='primary'
          onClick={onCancelClick}>
          Cancel
        </Button>
      </Box>

    </Container>
  )

  function getStrengthCard (strength: Strength, index: string) {
    // Only show mask if at least one item is selected
    let showMask = false
    if (strengthPageDone) {
      showMask = !strength.isSelected
    }
    return (
      <Card
        className={classes.card}
        style={{ backgroundColor: strength.color }}
        key={`a${index}`}>
        <CardActionArea
          key={`b${index}`}
          id={index}
          onClick={onStrengthClick}>
          <Typography
            className={classes.strengthLevel}
            style={{ color: strength.textColor }}
            key={`c${index}`}
            variant='body2'>
            {strength.level}
          </Typography>
          {showMask && <Card className={classes.cardMask} key={`d${index}`}/>}
        </CardActionArea>
      </Card>
    )
  }

  function onStrengthClick (event: React.MouseEvent<HTMLElement>) {
    // If the item was selected and is now tapped, deselect it
    const index = parseInt(event.currentTarget.id)
    if (strengths[index].isSelected) {
      dispatch(dataSetStrength(''))
      dispatch(mainSetStrengthPageDone(false))
    } else {
      // Another option is tapped, select that item and deselect everything else
      dispatch(dataSetStrength(strengths[index].level))
      dispatch(mainSetStrengthPageDone(true))
    }
  }

  function getDescription () {
    let description = ''
    strengths.forEach(_strength => {
      if (_strength.level === strength) {
        description = _strength.description
      }
    })
    return (
      <Typography className={classes.description} variant='body2' align='center'>
        {description}
      </Typography>
    )
  }

  function onContinueClick () {
    if (strengthPageDone) {
      history.push('/off')
    }
  }

  function onCancelClick () {
    dispatch(mainSetShowCancelDialog(true))
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    margin: theme.spacing(2)
  },
  centerContainer: {
    width: '100%'
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  strengthLevel: {
    margin: theme.spacing(1),
    verticalAlign: 'center',
    width: '100%',
    textAlign: 'center'
  },
  cardMask: {
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: 'white',
    opacity: '0.8',
    width: '100%',
    height: '100%'
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  fillerContainer: {
    flex: '1'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  continueButton: {
    margin: theme.spacing(1),
    height: '44px'
  },
  cancelButton: {
    marginBottom: theme.spacing(2)
  }
}))

export default StrengthPage