import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mainSetShowPrivacyPolicyDialog } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function PrivacyPolicyDialog () {

  const classes = useStyles()
  const dispatch = useDispatch()
  const showPrivacyPolicyDialog = useSelector((state: AppState) => state.main.showPrivacyPolicyDialog)

  return (
    <Dialog
      fullScreen
      open={showPrivacyPolicyDialog}
      onClose={onPrivacyPolicyDialogClose}>
      <DialogTitle>Privacy Policy</DialogTitle>
      <DialogContent dividers style={{ overflowY: 'scroll' }}>
        <DialogContentText variant='body1'>
          <div className={classes.blackText}>
            {'Last updated: 14/01/2021'}
          </div>
          <p>
            {'ERM("us", "we", or "our") operates https://odour.ermapps.com (the "Site"). ' +
            'This page informs you of our policies regarding the collection, ' +
            'use and disclosure of Personal Information we receive from users of the Site.'}
          </p>
          <p>
            {'We use your Personal Information only for providing and improving the Site. ' +
            'By using the Site, you agree to the collection and use of information in ' +
            'accordance with this policy.'}
          </p>
          <p className={classes.blackText}>
            {'Information Collection And Use'}
          </p>
          <p>
            {'While using our Site, we may ask you to provide us with certain personally ' +
            'identifiable information that can be used to contact or identify you. ' +
            'Personally identifiable information may include, but is not limited to your name, ' +
            'email address, contact phone number and location. ("Personal Information").'}
          </p>
          <p className={classes.blackText}>
            {'Log Data'}
          </p>
          <p>
            {'Like many site operators, we collect information that your browser sends whenever ' +
            'you visit our Site ("Log Data").'}
          </p>
          <p>
            {'This Log Data may include information such as your computer\'s Internet Protocol ' +
            '("IP") address, browser type, browser version, the pages of our Site that you visit, ' +
            'the time and date of your visit, the time spent on those pages and other statistics.'}
          </p>
          <p>
            {'In addition, we may use third party services such as Google Analytics that collect, ' +
            'monitor and analyse this information.'}
          </p>
          <p className={classes.blackText}>
            {'Communications'}
          </p>
          <p>
            {'We will not use your Personal Information to contact you with newsletters, marketing ' +
            'or promotional materials. However, your Personal Information may be user to contact ' +
            'you regarding the Odour Report(s) submitted via the app.'}
          </p>
          <p className={classes.blackText}>
            {'Cookies'}
          </p>
          <p>
            {'Cookies are files with small amount of data, which may include an anonymous unique identifier. ' +
            'Cookies are sent to your browser from a web site and stored on your computer\'s hard drive.'}
          </p>
          <p>
            {'Like many sites, we use "cookies" to collect information. ' +
            'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. ' +
            'However, if you do not accept cookies, you may not be able to use some portions of our Site.'}
          </p>
          <p className={classes.blackText}>
            {'Security'}
          </p>
          <p>
            {'The security of your Personal Information is important to us, but remember that no method of ' +
            'transmission over the Internet, or method of electronic storage, is 100% secure. ' +
            'While we strive to use commercially acceptable means to protect your Personal Information, ' +
            'we cannot guarantee its absolute security.'}
          </p>
          <p className={classes.blackText}>
            {'Changes To This Privacy Policy'}
          </p>
          <p>
            {'This Privacy Policy is effective as of 14/01/2021 and will remain in effect except with respect to ' +
            'any changes in its provisions in the future, which will be in effect immediately after being ' +
            'posted on this page.'}
          </p>
          <p>
            {'We reserve the right to update or change our Privacy Policy at any time and you should check ' +
            'this Privacy Policy periodically. Your continued use of the Service after we post any modifications ' +
            'to the Privacy Policy on this page will constitute your acknowledgment of the modifications and ' +
            'your consent to abide and be bound by the modified Privacy Policy.'}
          </p>
          <p>
            {'If we make any material changes to this Privacy Policy, we will notify you either through the ' +
            'email address you have provided us, or by placing a prominent notice on our website.'}
          </p>
          <p className={classes.blackText}>
            {'Contact Us'}
          </p>
          <p>
            {'If you have any questions about this Privacy Policy, ' +
            'please contact us via Contact Support page of the app.'}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPrivacyPolicyDialogClose} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )

  function onPrivacyPolicyDialogClose () {
    dispatch(mainSetShowPrivacyPolicyDialog(false))
  }

}

const useStyles = makeStyles(() => ({
  blackText: {
    color: 'black'
  }
}))

export default PrivacyPolicyDialog