import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import { dataSetComments, dataSetEmail, dataSetName, dataSetPhone } from '../../redux/slice/DataSlice'
import {
  mainSetShowCancelDialog,
  mainSetShowPrivacyPolicyDialog,
  mainSetSupportPageDone
} from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function SupportPage () {

  const classes = useStyles()
  const history = useHistory()

  const [nameError, setNameError] = useState(' ')    // Leaving whitespace as a hack so that vertical space for helperText is included
  const [phoneError, setPhoneError] = useState(' ')  // Or else it will shift other content down when it appears
  const [emailError, setEmailError] = useState(' ')
  const [commentsError, setCommentsError] = useState(' ')

  const dispatch = useDispatch()
  const name = useSelector((state: AppState) => state.data.name)
  const phone = useSelector((state: AppState) => state.data.phone)
  const email = useSelector((state: AppState) => state.data.email)
  const comments = useSelector((state: AppState) => state.data.comments)

  const supportPageDone = useSelector((state: AppState) => state.main.supportPageDone)
  useEffect(() => {
    dispatch(
      mainSetSupportPageDone(
        name.trim().length !== 0 &&
        phone.trim().length !== 0 &&
        email.trim().length !== 0 &&
        comments.trim().length !== 0 &&
        nameError === ' ' &&
        phoneError === ' ' &&
        emailError === ' ' &&
        commentsError === ' '
      )
    )
  }, [dispatch, name, phone, email, comments, nameError, phoneError, emailError, commentsError])

  useEffect(() => {
    // scroll to top in case it overflows the viewport
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  return (
    <Container maxWidth='xs' className={classes.root}>

      <Typography className={classes.title} variant='h5' align='center'>
        Contact Support
      </Typography>

      <Box className={classes.formContainer}>
        <TextField
          className={classes.textField}
          fullWidth
          name='name'
          value={name}
          label='Name'
          variant='outlined'
          size="small"
          error={nameError !== ' '}
          helperText={nameError}
          onChange={onTextFieldChange}/>
        <TextField
          className={classes.textField}
          fullWidth
          name='phone'
          value={phone}
          label='Phone Number'
          inputProps={{ inputMode: 'tel' }}
          variant='outlined'
          size="small"
          error={phoneError !== ' '}
          helperText={phoneError}
          onChange={onTextFieldChange}/>
        <TextField
          className={classes.textField}
          fullWidth
          name='email'
          value={email}
          label='Email'
          inputProps={{ inputMode: 'email' }}
          variant='outlined'
          size="small"
          error={emailError !== ' '}
          helperText={emailError}
          onChange={onTextFieldChange}/>
        <TextField
          fullWidth
          multiline
          rows={4}
          rowsMax={4}
          name='comments'
          value={comments}
          label='Describe your problem with this app'
          variant='outlined'
          size="small"
          error={commentsError !== ' '}
          helperText={commentsError}
          onChange={onTextFieldChange}/>
      </Box>

      <Box className={classes.fillerContainer}/>

      <Box className={classes.bottomContainer}>
        <Typography className={classes.privacyPolicy} variant='caption'>
          <a href={'#0'} onClick={onPrivacyPolicyClick}>
            By using this app, you agree to our Privacy Policy.
          </a>
        </Typography>
        <Button
          className={classes.continueButton}
          variant='contained'
          color='primary'
          disabled={!supportPageDone}
          onClick={onSubmitClick}>
          Submit
        </Button>
        <Button
          className={classes.cancelButton}
          color='primary'
          onClick={onCancelClick}>
          Cancel
        </Button>
      </Box>

    </Container>
  )

  function onTextFieldChange (event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    switch (event.target.name) {
      case 'name':
        const name = value.trim()
        setNameError(assignTextFieldError(name, isNameValid(name)))
        dispatch(dataSetName(value))
        break
      case 'phone':
        // Don't allow space
        const phone = value.trim()
        setPhoneError(assignTextFieldError(phone, isPhoneValid(phone)))
        dispatch(dataSetPhone(phone))
        break
      case 'email':
        // Don't allow space
        const email = value.trim()
        setEmailError(assignTextFieldError(email, isEmail(email)))
        dispatch(dataSetEmail(email))
        break
      case 'comments':
        const comments = value.trim()
        setCommentsError(assignTextFieldError(comments, isCommentsValid(comments)))
        dispatch(dataSetComments(value))
        break
    }
  }

  function assignTextFieldError (value: string, isValid: boolean) {
    if (value.length === 0) {
      return 'Required'
    } else {
      if (isValid) {
        return ' '
      } else {
        return 'Invalid value'
      }
    }
  }

  function isNameValid (name: string) {
    const regex = new RegExp('^([^0-9]*)$')
    return regex.test(name)
  }

  function isPhoneValid (phone: string) {
    // Australia phone number is at least 6 numbers, confirm after removing other characters
    if (phone.replace(/[-()+]/g, '').length < 6) return false
    const regex = new RegExp('^([0-9-()+]+$)')
    return regex.test(phone)
  }

  function isCommentsValid (comments: string) {
    return comments.length > 0
  }

  function onPrivacyPolicyClick (event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    dispatch(mainSetShowPrivacyPolicyDialog(true))
  }

  function onSubmitClick () {
    if (supportPageDone) {
      history.push('/submit')
    }
  }

  function onCancelClick () {
    dispatch(mainSetShowCancelDialog(true))
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formContainer: {
    width: '100%'
  },
  textField: {
    marginBottom: theme.spacing(1)
  },
  fillerContainer: {
    flex: '1'
  },
  privacyPolicy: {
    width: '100%',
    textAlign: 'center'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  continueButton: {
    margin: theme.spacing(1)
  },
  cancelButton: {
    marginBottom: theme.spacing(2)
  }
}))

export default SupportPage