import { Box, Button, Card, CardActionArea, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { dataSetDuration, dataSetOffensive } from '../../redux/slice/DataSlice'
import { mainSetOffensivePageDone, mainSetShowCancelDialog } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'
import { WhiteTextTypography } from '../../utils/WhiteTextTypography'

interface Option {
  text: string,
  color: string,
  isSelected: boolean
}

function OffensivePage () {

  const classes = useStyles()
  const history = useHistory()

  const [gridSize, setGridSize] = useState('')
  const [offensives, setOffensives] = useState([
    {
      text: 'Yes',
      color: 'red',
      isSelected: false
    }, {
      text: 'No',
      color: 'green',
      isSelected: false
    }
  ])
  const [durations, setDurations] = useState([
    {
      text: 'Under 10\nminutes',
      color: '#00B050',
      isSelected: false
    },
    {
      text: '10-30\nminutes',
      color: '#FFC000',
      isSelected: false
    },
    {
      text: '30-60\nminutes',
      color: '#C00000',
      isSelected: false
    },
    {
      text: 'Over\n1 hour',
      color: '#000000',
      isSelected: false
    }
  ])

  const dispatch = useDispatch()
  const isOffensive = useSelector((state: AppState) => state.data.isOffensive)
  useEffect(() => {
    setOffensives((offensives) => offensives.map(offensive => {
      return {
        ...offensive,
        isSelected: offensive.text === isOffensive
      }
    }))
  }, [isOffensive])

  const duration = useSelector((state: AppState) => state.data.duration)
  useEffect(() => {
    setDurations((durations) => durations.map(_duration => {
      return {
        ..._duration,
        isSelected: _duration.text === duration
      }
    }))
  }, [duration])

  const mapPageDone = useSelector((state: AppState) => state.main.mapPageDone)
  const smellPageDone = useSelector((state: AppState) => state.main.smellPageDone)
  const strengthPageDone = useSelector((state: AppState) => state.main.strengthPageDone)
  const offensivePageDone = useSelector((state: AppState) => state.main.offensivePageDone)

  useEffect(() => {
    calculateGridSize()
    // scroll to top in case it overflows the viewport
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  function calculateGridSize () {
    // For horizontal orientation, use a fix size since we would not fit anyway
    if (window.innerHeight < window.innerWidth) {
      setGridSize('80px')
    } else {
      // Else, fit according to screen width
      const gridContainerWidth = window.innerWidth - 16     // screen width - margins
      const gridSize = gridContainerWidth / 4 - 8           // margin is 4px per side
      setGridSize(`${gridSize}px`)
    }
  }

  // If previous page was not completed, go back
  if (
    !mapPageDone ||
    !smellPageDone ||
    !strengthPageDone
  ) {
    return (<Redirect to='/strength'/>)
  }
  return (
    <Box className={classes.root}>

      <Typography className={classes.title} variant='h5' align='center'>
        Would you say the odour is offensive?
      </Typography>

      <Box className={classes.gridContainer}>
        {offensives.map((offensive, index) => (
          getOffensiveCard(offensive, index.toString())
        ))}
      </Box>

      <Typography className={classes.title2} variant='h5' align='center'>
        How long has the odour lasted?
      </Typography>

      <Box className={classes.gridContainer}>
        {durations.map((duration, index) => (
          getDurationGrid(duration, index.toString())
        ))}
      </Box>

      <Box className={classes.fillerContainer}/>

      <Box className={classes.bottomContainer}>
        <Button
          className={classes.continueButton}
          variant='contained'
          color='primary'
          disabled={!offensivePageDone}
          onClick={onContinueClick}>
          Continue
        </Button>
        <Button
          className={classes.cancelButton}
          color='primary'
          onClick={onCancelClick}>
          Cancel
        </Button>
      </Box>

    </Box>
  )

  function getOffensiveCard (offensive: Option, index: string) {
    // Only show mask if at least one item is selected
    let showMask = false
    if (isOffensiveSelected()) {
      showMask = !offensive.isSelected
    }
    return (
      <Card
        style={{
          backgroundColor: offensive.color,
          margin: '4px',
          width: gridSize,
          height: gridSize
        }}
        key={`a${index}`}>
        <CardActionArea
          className={classes.gridCardActionArea}
          key={`b${index}`}
          id={index}
          onClick={onOffensiveClick}>
          <WhiteTextTypography className={classes.gridText} key={`c${index}`} variant='h5'>
            {offensive.text}
          </WhiteTextTypography>
          {showMask && <Card className={classes.gridCardMask} key={`d${index}`}/>}
        </CardActionArea>
      </Card>
    )
  }

  function onOffensiveClick (event: React.MouseEvent<HTMLElement>) {
    // If the item was selected and is now tapped, deselect it
    const index = parseInt(event.currentTarget.id)
    if (offensives[index].isSelected) {
      dispatch(dataSetOffensive(''))
      dispatch(mainSetOffensivePageDone(false))
    } else {
      // Another option is tapped, select that item and deselect everything else
      dispatch(dataSetOffensive(offensives[index].text))
      if (isDurationSelected()) {
        dispatch(mainSetOffensivePageDone(true))
      }
    }
  }

  function getDurationGrid (duration: Option, index: string) {
    // Only show mask if at least one item is selected
    let showMask = false
    if (isDurationSelected()) {
      showMask = !duration.isSelected
    }
    return (
      <Card
        style={{
          backgroundColor: duration.color,
          margin: '4px',
          width: gridSize,
          height: gridSize
        }}
        key={`e${index}`}>
        <CardActionArea
          className={classes.gridCardActionArea}
          key={`f${index}`}
          id={index}
          onClick={onDurationClick}>
          <WhiteTextTypography className={classes.gridText} key={`g${index}`} variant='body2'>
            {duration.text}
          </WhiteTextTypography>
          {showMask && <Card className={classes.gridCardMask} key={`h${index}`}/>}
        </CardActionArea>
      </Card>
    )
  }

  function onDurationClick (event: React.MouseEvent<HTMLElement>) {
    const index = parseInt(event.currentTarget.id)
    // If the item was selected and is now tapped, deselect it
    if (durations[index].isSelected) {
      dispatch(dataSetDuration(''))
      dispatch(mainSetOffensivePageDone(false))
    } else {
      // Another option is tapped, select that item and deselect everything else
      dispatch(dataSetDuration(durations[index].text))
      if (isOffensiveSelected()) {
        dispatch(mainSetOffensivePageDone(true))
      }
    }
  }

  function onContinueClick () {
    if (offensivePageDone) {
      history.push('/contact')
    }
  }

  function isOffensiveSelected () {
    return isOffensive.length > 0
  }

  function isDurationSelected () {
    return duration.length > 0
  }

  function onCancelClick () {
    dispatch(mainSetShowCancelDialog(true))
  }

}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  gridContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridCardActionArea: {
    width: '100%',
    height: '100%'
  },
  gridText: {
    verticalAlign: 'center',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },
  gridCardMask: {
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: 'white',
    opacity: '0.8',
    width: '100%',
    height: '100%'
  },
  title2: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  fillerContainer: {
    flex: '1'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  continueButton: {
    margin: theme.spacing(1),
    height: '44px'
  },
  cancelButton: {
    marginBottom: theme.spacing(2)
  }
}))

export default OffensivePage