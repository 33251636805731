import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCK1RrXZgyr_wIe6fJ2PTDl1wnf9AMzDGE',
  authDomain: 'air-odour.firebaseapp.com',
  projectId: 'air-odour',
  storageBucket: 'air-odour.appspot.com',
  messagingSenderId: '472595628628',
  appId: '1:472595628628:web:dbb2dd021137f8dfff63c1',
  measurementId: 'G-0BDBENFT67'
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

export const db = firebase.firestore()
