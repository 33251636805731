import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dataReset } from '../../redux/slice/DataSlice'
import { mainReset, mainSetShowSupportDialog } from '../../redux/slice/MainSlice'
import { AppState } from '../../redux/Stores'

function SupportDialog () {

  const history = useHistory()
  const dispatch = useDispatch()
  const showSupportDialog = useSelector((state: AppState) => state.main.showSupportDialog)

  return (
    <Dialog
      open={showSupportDialog}
      onClose={onSupportDialogClose}>
      <DialogTitle>Cancel report?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel report?
          All entered information will be lost.
          You will be redirected to Customer Support page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSupportDialogYes} color="primary">
          Yes
        </Button>
        <Button onClick={onSupportDialogClose} color="primary" variant="contained">
          No
        </Button>
      </DialogActions>
    </Dialog>
  )

  function onSupportDialogYes () {
    dispatch(mainReset())
    dispatch(dataReset())
    history.push('/support')
  }

  function onSupportDialogClose () {
    dispatch(mainSetShowSupportDialog(false))
  }

}

export default SupportDialog