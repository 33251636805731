import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialDataState, Smell } from '../state/DataState'

const dataSlice = createSlice({
  name: 'data',
  initialState: initialDataState,
  reducers: {
    dataReset () {
      return initialDataState
    },
    dataSetLatLng: {
      reducer (state, action: PayloadAction<{ lat: number, lng: number }>) {
        state.lat = action.payload.lat
        state.lng = action.payload.lng
      },
      prepare (lat: number, lng: number) {
        return { payload: { lat, lng } }
      }
    },
    dataSetSmells (state, action: PayloadAction<Array<Smell>>) {
      state.smells = action.payload
    },
    dataSetStrength (state, action: PayloadAction<string>) {
      state.strength = action.payload
    },
    dataSetOffensive (state, action: PayloadAction<string>) {
      state.isOffensive = action.payload
    },
    dataSetDuration (state, action: PayloadAction<string>) {
      state.duration = action.payload
    },
    dataSetName (state, action: PayloadAction<string>) {
      state.name = action.payload
    },
    dataSetPhone (state, action: PayloadAction<string>) {
      state.phone = action.payload
    },
    dataSetEmail (state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    dataSetComments (state, action: PayloadAction<string>) {
      state.comments = action.payload
    }
  }
})

export const {
  dataReset,
  dataSetLatLng,
  dataSetSmells,
  dataSetStrength,
  dataSetComments,
  dataSetDuration,
  dataSetEmail,
  dataSetName,
  dataSetOffensive,
  dataSetPhone
} = dataSlice.actions

export default dataSlice.reducer