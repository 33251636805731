export interface MainState {
  appBarHeight: number,
  mapPageDone: boolean,
  smellPageDone: boolean,
  strengthPageDone: boolean,
  offensivePageDone: boolean,
  contactPageDone: boolean,
  supportPageDone: boolean,
  showCancelDialog: boolean,
  showAboutDialog: boolean,
  showSupportDialog: boolean,
  showPrivacyPolicyDialog: boolean
}

export const initialMainState: MainState = {
  appBarHeight: 56,
  mapPageDone: false,
  smellPageDone: false,
  strengthPageDone: false,
  offensivePageDone: false,
  contactPageDone: false,
  supportPageDone: false,
  showCancelDialog: false,
  showAboutDialog: false,
  showSupportDialog: false,
  showPrivacyPolicyDialog: false
}