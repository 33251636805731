import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import TopAppBar from './components/appbar/TopAppBar'
import AboutDialog from './components/dialog/AboutDialog'
import CancelDialog from './components/dialog/CancelDialog'
import PrivacyPolicyDialog from './components/dialog/PrivacyPolicyDialog'
import SupportDialog from './components/dialog/SupportDialog'
import ContactPage from './components/page/ContactPage'
import MapPage from './components/page/MapPage'
import NotFoundPage from './components/page/NotFoundPage'
import OffensivePage from './components/page/OffensivePage'
import ReportPage from './components/page/ReportPage'
import SmellPage from './components/page/SmellPage'
import StrengthPage from './components/page/StrengthPage'
import SubmitPage from './components/page/SubmitPage'
import SupportPage from './components/page/SupportPage'
import { AppState } from './redux/Stores'

function App () {

  const classes = useStyles()
  const appBarHeight = useSelector((state: AppState) => state.main.appBarHeight)

  // Note that the the AppBar is fixed at the top, the container will add a top margin of height of AppBar
  return (
    <Box className={classes.root}>
      <TopAppBar/>
      <Box
        className={classes.pageContainer}
        style={{ marginTop: `${appBarHeight}px` }}>

        <Switch>
          <Route exact path='/' component={MapPage}/>
          <Route exact path='/smell' component={SmellPage}/>
          <Route exact path='/strength' component={StrengthPage}/>
          <Route exact path='/off' component={OffensivePage}/>
          <Route exact path='/contact' component={ContactPage}/>
          <Route exact path='/submit' component={SubmitPage}/>
          <Route exact path='/support' component={SupportPage}/>
          <Route exact path='/report' component={ReportPage}/>
          <Route component={NotFoundPage}/>
        </Switch>

        <CancelDialog/>
        <AboutDialog/>
        <SupportDialog/>
        <PrivacyPolicyDialog/>

      </Box>
    </Box>
  )

}

const useStyles = makeStyles(() => ({
  // Use flex to fill height, see https://stackoverflow.com/a/24979148 and https://jsfiddle.net/przemcio/xLhLuzf9/3/
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white'
  },
  pageContainer: {
    flex: '1'
  }
}))

export default App
